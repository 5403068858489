import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from './services/form/form.service';
import { NotificationService } from './services/notification/notification.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-top-right'
    }),
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    FormService,
    NotificationService
  ]
})
export class CoreModule { }
