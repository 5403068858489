import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

const config: ExtraOptions = {
  anchorScrolling: "enabled",
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0,50]
}

const routes: Routes = [
  {
    path: '', redirectTo: 'inicio', pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./visitor/visitor.module')
      .then(m => m.VisitorModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
